import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IG = () => (
    <Layout>
        <SEO title="Welcome from Instagram" />
        <div className="container">
            <div id="instragram-landing-page" className="row">
                <div className="col-sm-12">
                    <h1 className="text-center">Hello from Instagram</h1>
                    <p className="text-center">Below you will find all of my links:</p>
                    <ul className="ig-links">
                        <li><a href="https://www.twitch.tv/notserk"><i className="fab fa-twitch"></i>Tune in to my stream</a></li>
                        <li><a href="https://www.youtube.com/channel/UCHQnuYhOuAGEYPI6vMIce_A"><i className="fab fa-youtube-square"></i>Subscribe on Youtube</a></li>
                        <li><a href="https://twitter.com/notserk"><i className="fab fa-twitter-square"></i>Follow on Twitter</a></li>
                        <li><a href="https://www.facebook.com/notserk"><i className="fab fa-facebook-square"></i>Follow on Facebook</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </Layout>
)

export default IG